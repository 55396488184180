<template>
    <v-container fluid class="ma-0 mb-3 pa-0 pl-2 pt-2">
        <v-row class="justify-space-arround">
            <v-col cols="12" xl="7">
                <v-card class="default" outlined height="100%">
                    <v-toolbar short flat color="secondary">
                        <v-toolbar-title
                            class="text-subtitle-1 primary--text"
                            v-text="$t('settings.contact.generalInformation')"
                        />
                    </v-toolbar>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-text-field
                            v-model="model.title"
                            :label="$t('settings.contact.title.label')"
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-text-field
                            v-model="model.firstName"
                            :error-messages="errors.collect('firstName')"
                            :label="$t('settings.contact.firstName.label')"
                            data-vv-name="firstName"
                            v-validate="'required'"
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-text-field
                            v-model="model.lastName"
                            :error-messages="errors.collect('lastName')"
                            :label="$t('settings.contact.lastName.label')"
                            data-vv-name="lastName"
                            v-validate="'required'"
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-text-field
                            v-model="model.jobTitle"
                            :error-messages="errors.collect('jobTitle')"
                            :label="$t('settings.contact.jobTitle.label')"
                            data-vv-name="jobTitle"
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-text-field
                            v-model="model.email"
                            :error-messages="errors.collect('email')"
                            :label="$t('settings.contact.email.label')"
                            data-vv-name="email"
                            v-validate="
                                model.isUser ? 'required|email' : 'email'
                            "
                            spellcheck="true"
                            @input="$emit('modified', $event)"
                        ></v-text-field>
                        <v-switch
                            v-model="model.isUser"
                            :label="$t('settings.contact.isUser.label')"
                            @change="$emit('modified', $event)"
                        />
                        <template v-if="model.isUser">
                            <v-text-field
                                v-model="model.password"
                                autocomplete="new-password"
                                :error-messages="errors.collect('password')"
                                :label="$t('settings.contact.password.label')"
                                :hint="$t('settings.contact.password.hint')"
                                data-vv-name="password"
                                v-validate="'min:8'"
                                type="password"
                                spellcheck="true"
                                persistent-hint
                                @input="$emit('modified', $event)"
                            ></v-text-field>
                            <v-autocomplete
                                v-model="languageModel"
                                :items="languageItems"
                                :label="$t('settings.contact.language.label')"
                                :error-messages="errors.collect('language')"
                                data-vv-name="language"
                                v-validate="'required'"
                                item-text="itemText"
                                item-value="itemValue"
                                spellcheck="true"
                                @input="$emit('modified', $event)"
                            ></v-autocomplete>
                            <v-autocomplete
                                v-model="roleModel"
                                :items="roleItems"
                                :label="$t('settings.contact.role.label')"
                                :error-messages="errors.collect('role')"
                                data-vv-name="role"
                                v-validate="'required'"
                                item-text="roleDescription"
                                item-value="roleName"
                                spellcheck="true"
                                :disabled="editingMySelf"
                                @input="$emit('modified', $event)"
                            ></v-autocomplete>
                            <v-switch
                                v-model="model.disableNotification"
                                :label="$t('settings.contact.disableNotification.label')"
                                :hint="$t( 'settings.contact.disableNotification.hint')"
                                persistent-hint
                                @change="$emit('modified', $event)"
                            />
                        </template>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" xl="5">
                <v-card class="default" outlined height="100%">
                    <v-toolbar short flat color="secondary">
                        <v-toolbar-title
                            class="text-subtitle-1 primary--text"
                            v-text="$t('settings.contact.assignments')"
                        />
                    </v-toolbar>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-autocomplete
                            :items="companyDepartmentItems"
                            :value="selectedCompany"
                            item-value="id"
                            item-text="title"
                            @change="showCompanyDepartments($event)"
                            :label="$t('settings.contact.selectCompany')"
                            solo
                            returnObject
                            clearable
                        />
                        <v-dialog v-model="companyDepartmentDialog" width="500">
                            <v-card color="secondary">
                                <v-card-title
                                    class="text-h5"
                                    primary-title
                                >
                                    {{ $t('settings.contact.selectDepartments') }}
                                </v-card-title>

                                <GenericItemList
                                    disableSorting
                                    returnObject
                                    :items="addableCompanyDepartments"
                                    :itemText="'title'"
                                    :max-height="600"
                                    @select="onSelectCompanyDepartment($event)"
                                />

                                <v-divider></v-divider>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="primary2"
                                        text
                                        @click="closeCompanyDepartmentDialog()"
                                    >
                                        {{ $t('back') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-container class="ma-0 pa-0">
                            <v-row>
                                <template
                                    v-if="model.companies && model.companies.length"
                                >
                                    <v-col
                                        cols="12"
                                        :lg="(model.companies.length > 1) ? 6 : 12"
                                        :xl="(model.companies.length > 2) ? 4 : (model.companies.length > 1) ? 6 : 12"
                                        v-for="company in model.companies"
                                        :key="company.id"
                                    >
                                        <v-card outlined height="100%">
                                            <v-toolbar dense flat color="secondary">
                                                <v-toolbar-title
                                                    class="text-subtitle-2 primary--text"
                                                    >{{
                                                        company.title
                                                    }}</v-toolbar-title>
                                            </v-toolbar>
                                            <v-list>
                                                <v-list-item
                                                    v-for="department in company.departments"
                                                    :key="department.id"
                                                >
                                                    <v-list-item-action
                                                        style="min-width:34px"
                                                    >
                                                        <v-btn
                                                            icon
                                                            @click="
                                                                removeDepartment(
                                                                    company.id,
                                                                    department.id
                                                                )
                                                            "
                                                            ><v-icon color="error"
                                                                >mdi-close</v-icon
                                                            ></v-btn
                                                        >
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-tooltip
                                                            bottom
                                                            color="primary"
                                                        >
                                                            <template
                                                                v-slot:activator="{
                                                                    on,
                                                                }"
                                                            >
                                                                <v-list-item-title
                                                                    v-on="on"
                                                                    v-text="
                                                                        department.title
                                                                    "
                                                                />
                                                            </template>
                                                            <span
                                                                v-text="
                                                                    department.title
                                                                "
                                                            ></span>
                                                        </v-tooltip>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-card>
                                    </v-col>
                                </template>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
const GenericItemList = () => import(/* webpackChunkName: "GenericItemList" */ '@/components/generic/list/ItemList.vue');
export default {
    name: 'SettingsStaffEditorMask',
    inject: {
        $validator: '$validator',
    },
    components: {
        GenericItemList,
    },
    props: {
        value: Object,
        currentLanguage: String,
        languageItems: {
            type: Array,
            default: () => [],
        },
        companyDepartmentItems: {
            type: Array,
            default: () => [],
        },
    },
    i18n: {
        messages: {
            en: require('@/locales/Settings/en.json'),
            de: require('@/locales/Settings/de.json'),
        },
    },
    data() {
        return {
            model: this.value,
            companyDepartmentDialog: false,
            selectedCompany: null,
            addableCompanyDepartments: [],
            roles: [
                'ROLE_TENANT_ADMIN',
                'ROLE_TENANT_EDITOR_CREATOR_DELETOR',
                'ROLE_TENANT_EDITOR',
                'ROLE_TENANT_READER_WORKFLOW_COORD',
                'ROLE_WORKFLOW_USER',
                'ROLE_TENANT_READER',
                'ROLE_EDITOR_CREATOR_DELETOR',
                'ROLE_EDITOR',
                'ROLE_READER',
                'ROLE_ADMIN_API'
            ],
        };
    },
    created() {
        if (!this.languageModel) {
            this.languageModel = this.currentLanguage;
        }
        if (!this.roleModel && (!this.model.id || this.$isString(this.model.id)) ) {
            this.roleModel = 'ROLE_READER';
        }
    },
    computed: {
        languageModel: {
            get() {
                return this.model.language;
            },
            set(val) {
                this.model.language = val;
            },
        },
        roleModel: {
            get() {
                return this.model.role;
            },
            set(val) {
                this.model.role = val;
            },
        },
        editingMySelf() {
            return this.$myContactId() === this.model.id;
        },
        roleItems() {
            const arrRoles = [];
            this.roles.forEach(x => {
                let roleDescription = this.$hasRole('ROLE_MASTER_EDITOR') ? this.$t('settings.contact.role.items.' + x) + ' //' + x : this.$t('settings.contact.role.items.' + x);
                arrRoles.push({
                    roleName: x,
                    roleDescription: roleDescription,
                });
            });
            return arrRoles;
        },
    },
    methods: {
        setAddableCompanyDepartments() {
            if (!this.selectedCompany || !this.selectedCompany.departments) {
                this.addableCompanyDepartments = [];
                return this.addableCompanyDepartments;
            }
            if (!this.model.companies) {
                this.addableCompanyDepartments = [
                    ...[{ id: 'all', title: this.$t('all') }],
                    ...this.selectedCompany.departments,
                ];
                return this.addableCompanyDepartments;
            }
            const currentModelCompany = this.model.companies.find(
                x => x.id === this.selectedCompany.id
            );
            if (currentModelCompany) {
                const currentSelectedDepartments = currentModelCompany.departments.map(
                    x => x.id
                );
                const departments = this.selectedCompany.departments.filter(
                    x => !currentSelectedDepartments.includes(x.id)
                );
                this.addableCompanyDepartments = [
                    ...[{ id: 'all', title: this.$t('all') }],
                    ...departments,
                ];
                return this.addableCompanyDepartments;
            }
            this.addableCompanyDepartments = [
                ...[{ id: 'all', title: this.$t('all') }],
                ...this.selectedCompany.departments,
            ];
            return this.addableCompanyDepartments;
        },
        closeCompanyDepartmentDialog() {
            this.companyDepartmentDialog = false;
            this.selectedCompany = null;
        },
        showCompanyDepartments(val) {
            if (val) {
                this.selectedCompany = JSON.parse(
                    JSON.stringify(
                        this.companyDepartmentItems.find(x => x.id === val.id)
                    )
                );
                this.setAddableCompanyDepartments();
                this.companyDepartmentDialog = true;
            }
        },
        onSelectCompanyDepartment(val) {
            if (!this.model.companies) {
                this.model.companies = [];
            }
            let companyIndex = this.model.companies.findIndex(
                x => x.id === this.selectedCompany.id
            );
            if (companyIndex === -1) {
                if (val.id === 'all') {
                    this.model.companies.unshift(this.selectedCompany);
                } else {
                    this.model.companies.unshift({
                        id: this.selectedCompany.id,
                        title: this.selectedCompany.title,
                        departments: [val],
                    });
                }
            } else {
                if (val.id === 'all') {
                    this.$set(
                        this.model.companies,
                        companyIndex,
                        this.selectedCompany
                    );
                } else {
                    if (
                        this.model.companies[companyIndex].departments.find(
                            x => x.id !== val.id
                        )
                    ) {
                        this.model.companies[companyIndex].departments.unshift(
                            val
                        );
                    }
                }
            }
            companyIndex = this.model.companies.findIndex(
                x => x.id === this.selectedCompany.id
            );
            this.$set(this.model, 'companies', this.model.companies);
            this.setAddableCompanyDepartments();
            if (
                this.addableCompanyDepartments.length === 1 &&
                this.addableCompanyDepartments[0].id === 'all'
            ) {
                this.companyDepartmentDialog = false;
                this.selectedCompany = null;
            }
            this.$forceUpdate();
            
            this.$emit('modified');
        },
        removeDepartment(companyId, departmentId) {
            const company = this.model.companies.find(x => x.id === companyId);
            if (company) {
                company.departments = company.departments.filter(
                    x => x.id !== departmentId
                );
                if (!company.departments.length) {
                    this.$set(
                        this.model,
                        'companies',
                        this.model.companies.filter(x => x.id !== companyId)
                    );
                }
            }
            this.setAddableCompanyDepartments();
            this.$forceUpdate();
            this.$emit('modified');
        },
    },
};
</script>
